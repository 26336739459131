/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(_components.p, null, "Minimalism is a design philosophy that emphasizes simplicity and functionality over ornamentation.\nIn web design, minimalism can be a powerful tool for creating clean, user-friendly websites that are\neasy to navigate and understand. But minimalism is not just about removing elements from a design,\nit's about creating a balance between negative space and the elements that remain. In this blog\npost, we will explore the benefits of minimalism in web design, the key principles of minimalistic\ndesign, and how to create a visually stunning, yet simple website.");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
